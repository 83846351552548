import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {ApiService} from '../../../services/api.service';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {debounceTime, map} from 'rxjs/operators';
import {Keywords} from '../../../interfaces/keywords';
import CSVFileValidator from 'csv-file-validator';
import {ImportDialogComponent} from '../import-dialog/import-dialog.component';
import {Audience} from '../../../interfaces/audience';
import {AudienceService} from '../../../services/audience/audience.service';
import {SnackBarService} from '../../../services/snack-bar/snack-bar.service';
import {DetailResponse} from '../../../interfaces/base/response';
import {TranslateService} from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import * as fromApp from 'src/app/store/app.reducer';

@Component({
    selector: 'app-save-audience-dialog',
    templateUrl: './save-audience-dialog.component.html',
    styleUrls: ['./save-audience-dialog.component.scss']
})
export class SaveAudienceDialogComponent implements OnInit {

    /* File uploading vars */
    uploadingCSV = false;
    importFile: File = null;
    importFileKeywordsCount: number = null;

    /* Expander vars */
    removable = true;
    selectable = true;
    customAudience = false;

    /* Forms */
    audienceForm: FormGroup;
    editingAudience: Audience;

    /* Other */
    keywordCtrl = new FormControl();
    activeExpander = ActiveExpander.CUSTOM;
    filteredKeywords: Observable<string[]>;
    readonly debounceTimeForSearchRequest: number = 500; // milliseconds (0.5 sec)
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    enableAudienceImport = false;

    @ViewChild('uploader') fileInput: ElementRef;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    @ViewChild('keywordInput') keywordInput: ElementRef<HTMLInputElement>;

    constructor(public dialog: MatDialog,
                private apiService: ApiService,
                private formBuilder: FormBuilder,
                public snackBar: SnackBarService,
                private audienceService: AudienceService,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private translateService: TranslateService,
                public dialogRef: MatDialogRef<SaveAudienceDialogComponent>,
                private store: Store<fromApp.AppState>) {
        this.editingAudience = this.data;
    }

    ngOnInit(): void {

        this.store.select('config').subscribe(res => {
            if (res.features.find(feature => feature.itemid === 'audience_csv_import')) {
                this.enableAudienceImport = true;
            }
        })

        this.initSearch();
        this.initReactiveForm();
    }

    initReactiveForm() {
        this.audienceForm = this.formBuilder.group({
            itemid: [this.editingAudience ? this.editingAudience.itemid : null], // only for editing and mustn't be updated
            name: [this.editingAudience ? this.editingAudience.name : null, [Validators.required]],
            filterKeywords: [this.editingAudience && this.editingAudience.filter_keywords ? this.editingAudience.filter_keywords : []]
        });
    }

    initSearch() {
        this.keywordCtrl.valueChanges.pipe(debounceTime(this.debounceTimeForSearchRequest)).subscribe((search: string) => {
            this.findAllKeywords(search);
        });
    }

    findAllKeywords(search?: string) {
        this.filteredKeywords = this.apiService.getKeywords(search).pipe(map((keywords: Keywords[]) => {
            return keywords.map(value => value.name);
        }));
    }

    selectAndAdd(event: MatAutocompleteSelectedEvent): void {
        const viewValue = event.option.viewValue;
        // check if already exists in the list
        if (!this.filterKeywordsControl.value.includes(viewValue)) {
            this.filterKeywordsControl.value.push(viewValue.trim());
        }
        this.keywordInput.nativeElement.value = '';
        this.keywordCtrl.setValue(null);
    }

    remove(keyword: string): void {
        const index = this.filterKeywordsControl.value.indexOf(keyword);

        if (index >= 0) {
            this.filterKeywordsControl.value.splice(index, 1);
        }
    }

    uploadFile(file) {
        const checkUser = {
            headers: [
                {
                    name: 'user',
                    inputName: 'user',
                },
            ],
        };
        const checkDevice = {
            headers: [
                {
                    name: 'device',
                    inputName: 'device',
                },
            ],
        };
        const firstFile: File = file.target.files[0];

        CSVFileValidator(firstFile, checkUser, checkDevice).then((dataWrapper) => {
            let isValid = false;
            if (!dataWrapper.inValidMessages || dataWrapper.inValidMessages.length <= 0) {
                isValid = true;
            }
            dataWrapper.data.shift();
            this.openImportDialog(firstFile, isValid, dataWrapper.data);
        });
    }

    openImportDialog(file: File, validImport: boolean, data: any) {
        const importDialog = this.dialog.open(ImportDialogComponent, {
            width: '500px',
            data: {
                fileName: file.name,
                valid: validImport,
                csvData: data,
            },
        });
        importDialog.afterClosed().subscribe((toSave?: boolean) => {
            if (toSave) {
                this.importFile = file;
                this.importFileKeywordsCount = data?.length;
            } else {
                this.importFile = null;
                this.importFileKeywordsCount = null;
                this.fileInput.nativeElement.value = '';
            }
        });
    }

    save() {
        if (this.activeExpander === this.customExpanderEnum) {
            this.saveCustom();
        } else if (this.activeExpander === this.fileImportExpanderEnum) {
            this.saveFromFile();
        }
    }

    saveCustom(): void {
        if (this.activeExpander === this.customExpanderEnum) {
            if (this.audienceForm.valid) {
                const formValue = this.audienceForm.value;
                this.uploadingCSV = true;
                this.audienceService.save({filter_keywords: formValue.filterKeywords, ...formValue})
                    .subscribe((response: DetailResponse<Audience>) => {
                        this.uploadingCSV = false;
                        this.snackBar.success(this.translateService.instant('app.audience.saveDialog.toaster.customSave.success'));
                        this.closeDialog(response);
                    }, (err) => {
                        this.uploadingCSV = false;
                        const errorStatus = err.error?.status;
                        if (errorStatus === 409) {
                            this.snackBar.error(this.translateService.instant('app.audience.saveDialog.toaster.customSave.error.409'));
                        } else {
                            this.snackBar.error(this.translateService
                                .instant('app.audience.saveDialog.toaster.customSave.error.default'));
                        }
                    });
            } else {
                this.audienceForm.markAsDirty();
                this.audienceForm.markAsTouched();
            }
        }
    }

    saveFromFile() {
        if (this.filterNameControl.valid) {
            if (this.importFile) {
                this.uploadingCSV = true;
                this.apiService
                    .postCSV(this.importFile, this.filterNameControl.value)
                    .subscribe((res) => {
                        this.snackBar.success(this.translateService.instant('app.audience.saveDialog.toaster.fileImport.success'));
                        this.closeDialog(res);
                    }, () => {
                        this.snackBar.error(this.translateService.instant('app.audience.saveDialog.toaster.fileImport.error'));
                    });
            } else {
                this.snackBar.warn(this.translateService.instant('app.audience.saveDialog.toaster.fileImport.warn'));
            }
        } else {
            this.filterNameControl.markAsDirty();
            this.filterNameControl.markAllAsTouched();
        }
    }

    closeDialog(result?: any): void {
        this.dialogRef.close(result);
    }

    /* ***** Getters ***** */

    // use getter method to access keywords control value easily
    get filterNameControl(): AbstractControl | null {
        return this.audienceForm.get('name');
    }

    // use getter method to access keywords control value easily
    get filterKeywordsControl(): AbstractControl | null {
        return this.audienceForm.get('filterKeywords');
    }

    // if the editing audience is not null then for editing is not possible to upload the file (disable file uploading accordion)
    get isDisabledUploadAccordion(): boolean {
        return this.editingAudience != null;
    }

    get customExpanderEnum(): ActiveExpander {
        return ActiveExpander.CUSTOM;
    }

    get fileImportExpanderEnum(): ActiveExpander {
        return ActiveExpander.FILE_IMPORT;
    }

}

export enum ActiveExpander {
    CUSTOM = 0,
    FILE_IMPORT = 1
}
