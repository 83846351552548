import { ShowRecipientsComponent } from './../../dialogs/show-recipients/show-recipients.component';
import {TranslateService} from '@ngx-translate/core';
import {CdkDetailRowDirective} from './../../../directives/cdk-detail-row.directive';
import {ApiService} from './../../../services/api.service';
import {SnackbarComponent} from './../../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {WizardComponent} from './../../wizard/wizard.component';
import {environment} from './../../../../environments/environment';
import {Campaign} from './../../../interfaces/campaign';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChildren} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger, } from '@angular/animations';
import {DeleteConfirmationComponent} from '../../dialogs/delete-confirmation/delete-confirmation.component';


@Component({
  selector: 'app-table-card',
  templateUrl: './table-card.component.html',
  styleUrls: ['./table-card.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'void',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TableCardComponent implements OnInit, OnChanges {
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private apiService: ApiService,
    public snackbar: MatSnackBar,
    public translate: TranslateService,
  ) {}
  environment = environment;
  @Input() searchTerm = '';
  @Input() campaignStatus = '';
  @Input() update: boolean;

  @Input() currentPage = 0;
  @Input() overallSize = 0;
  @Input() hasPrevPage = false;
  @Input() hasNextPage = false;
  @Input() loadingFailed = false;
  @Input() tableIsLoading = false;
  @Input() displayedColumns: string[] = [];
  @Input() dataSource: MatTableDataSource<Campaign>;

  @Output() prevPage: EventEmitter<void> = new EventEmitter();
  @Output() nextPage: EventEmitter<void> = new EventEmitter();
  @Output() reload: EventEmitter<boolean> = new EventEmitter();

  @ViewChildren(CdkDetailRowDirective) rowDetail;


  expand = true;
  allRowsExpanded = false;

  expandedElement: Campaign | null;

  isLoading = true;
  pageData = {};
  // tableIsLoading = false;
  page = 1;
  tableOffset = 0;
  pageLimit = 10;
  // loadingFailed = false;
  campaignStats: any = [];
  showStats: boolean;

  fetchingChart = false;

  showStatsNumbers = {
    reached: false,
    opened: false,
    dismissed: false,
    failed: false,
  };

  isExpansionDetailRow = (index, row) => row.hasOwnProperty('detailRow');

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  loadTableData() {
    this.tableIsLoading = true;
    this.loadingFailed = false;
    this.dataSource = new MatTableDataSource();
    this.apiService
      .getCampaign(this.campaignStatus, 10, this.tableOffset, this.searchTerm)
      .subscribe(
        (res) => {
          this.tableIsLoading = false;
          this.loadingFailed = false;
          this.dataSource = new MatTableDataSource(res.list);
          this.pageData = res;
        },
        (err) => {
          this.tableIsLoading = false;
          this.loadingFailed = true;
          this.snackbar.openFromComponent(SnackbarComponent, {
            duration: 8000,
            panelClass: ['warn-snackbar'],
            horizontalPosition: 'right',
            data: {
              type: 1,
            },
          });
        }
      );
  }

  pagePrev() {
    this.page--;
    this.tableOffset = this.tableOffset - 10;
    // this.loadTableData();
  }
  pageNext() {
    this.page++;
    this.tableOffset = this.tableOffset + 10;
    // this.loadTableData();
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  getRowDetail(row) {}

  openWizard(rowDetails) {
    console.log(rowDetails);

    let secondStep = 0;
    let thirdStep = 0;

    if (rowDetails.audience.filter_keywords.length > 0) {
      secondStep = 1;
    }

    if (rowDetails.trigger.trigger_type === 'exact_date') {
      thirdStep = 1;
    }

    const wizardConfig = {
      width: '90%',
      height: '75%',
      maxWidth: '1100px',
      maxHeight: '600px',
      panelClass: 'wizard-panel',
      data: {
        row: rowDetails,
        first: 0,
        second: secondStep,
        third: thirdStep,
      },
    };
    this.dialog
      .open(WizardComponent, wizardConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res === true) {
          this.loadTableData();
        }
      });
  }
  deleteCampaign(itemid) {
    const deleteDialog = this.dialog.open(DeleteConfirmationComponent, {
      data: {
        title: 'Delete scheduled campaign',
        description: 'Are you sure that you want to delete this campaign?'
      }
    });
    deleteDialog.afterClosed().subscribe((res) => {
      if (res === true) {
        this.apiService.deleteCampaign(itemid).subscribe((res) => {
          this.loadTableData();
        });
      }
    });
  }

  onToggleChange(cdkDetailRow: CdkDetailRowDirective): void {
    // if (this.openedRow && this.openedRow.expended) {
      // this.openedRow.toggle();
    // }
      // this.openedRow = cdkDetailRow.expended ? cdkDetailRow : undefined;
  }

  getCampaignStats(content, reset?: boolean) {
    if (reset) {
      this.showStatsNumbers.reached = false;
      this.showStatsNumbers.opened = false;
      this.showStatsNumbers.dismissed = false;
      this.showStatsNumbers.failed = false;
    }
    if (content.status === 'completed') {
      this.fetchingChart = true;
      this.apiService.getCampaignStats(content.itemid).subscribe((res) => {
        const campaignStats = res.detail;
        // const campaignStats = {
        //   audience_dismissed: 15,
        //   audience_opened: 25,
        //   audience_reached: 75,
        //   audience_disabled: 10,
        //   audience_unclear: null,
        //   failed_count: 5,
        //   total: null,
        //   total_percent: null,
        //   audience_reached_percent: null,
        //   audience_opened_percent: null,
        //   audience_dismissed_percent: null,
        //   audience_failed_count_percent: null,
        //   audience_disabled_percent: null,
        //   audience_unclear_percent: null,
        //   itemid: '304'
        // };
        // console.log(campaignStats);
        this.fetchingChart = false;

        this.showStats = !(res.detail.audience_dismissed === 0 && res.detail.audience_opened === 0 && res.detail.audience_reached === 0 && res.detail.failed_count === 0);

        campaignStats.total =
          campaignStats.audience_reached + campaignStats.failed_count;
        campaignStats.total_percent = 100;

        const collection = campaignStats.audience_opened + campaignStats.audience_dismissed;

        campaignStats.audience_reached_percent = campaignStats.audience_reached / campaignStats.total * 100;

        campaignStats.audience_opened_percent =
          // 10;
          (campaignStats.audience_opened / campaignStats.total) * 100;

        campaignStats.audience_dismissed_percent =
          // 30;
          (campaignStats.audience_dismissed / campaignStats.total) *
          100;

        campaignStats.audience_disabled_percent =
          // 30;
          (campaignStats.audience_disabled / campaignStats.total) *
          100;

        campaignStats.audience_unclear = campaignStats.audience_reached - (campaignStats.audience_dismissed + campaignStats.audience_opened + campaignStats.audience_disabled);

        campaignStats.audience_unclear_percent =
          // 30;
          (campaignStats.audience_unclear / campaignStats.total) *
          100;

        campaignStats.audience_failed_count_percent =
          // 40;
          (campaignStats.failed_count / campaignStats.total) * 100;

        if (this.campaignStats.length === 0) {
          this.campaignStats.push(campaignStats);
          } else {
              if (this.campaignStats.some(x => x.itemid === campaignStats.itemid)) {
              } else {
                this.campaignStats.push(campaignStats);
              }
          }
      });
    }
  }
  showStatNumber(stat) {
    switch (stat) {
      case 'reached':
        this.showStatsNumbers.reached = !this.showStatsNumbers.reached;
        break;
      case 'opened':
        this.showStatsNumbers.opened = !this.showStatsNumbers.opened;
        break;
      case 'dismissed':
        this.showStatsNumbers.dismissed = !this.showStatsNumbers.dismissed;
        break;
      case 'failed':
        this.showStatsNumbers.failed = !this.showStatsNumbers.failed;
        break;
    }
  }
  getStatus(){
    switch (this.campaignStatus) {
      case 'completed':
        return this.translate.instant('app.button.copyCampaign');

        case 'publish':
          return this.translate.instant('app.button.editCampaign');
    }
  }
  showRecipients(el){
    this.dialog.open(ShowRecipientsComponent, {
      width: '90%',
      maxWidth: '350px',
      minHeight: '500px',
      data: el
    });
  }
  expandRows(){
    this.allRowsExpanded = !this.allRowsExpanded;

    this.dataSource.data.forEach((element, i) => {
        if (this.allRowsExpanded) {
          this.rowDetail._results[i].open();
          this.getCampaignStats(element, true);
        } else {
          this.rowDetail._results[i].close();
        }
    });
  }
  paginator(isNext: boolean){
    if (isNext) {
      this.nextPage.emit();
    } else {
      this.prevPage.emit();
    }
    this.allRowsExpanded = false;
  }
}

