<div mat-dialog-content class="wizard-content">
    <div class="preview">
        <!-- <mat-expansion-panel style="position: absolute; top: 0; left: 0; width: 100%;">
            <mat-expansion-panel-header>
                Valid to send?
            </mat-expansion-panel-header>
            <div [ngSwitch]="checkContentForm.valid">
                <h3 style="display:flex; align-items: center">
                    <mat-icon class="icon-success" svgIcon="check" *ngSwitchCase="true"></mat-icon>
                    <mat-icon class="icon-warn" svgIcon="close" *ngSwitchCase="false"></mat-icon>
                    Content
                </h3>
            </div>

            <div [ngSwitch]="checkAudienceForm.valid">
                <h3 style="display:flex; align-items: center">
                    <mat-icon class="icon-success" svgIcon="check" *ngSwitchCase="true"></mat-icon>
                    <mat-icon class="icon-warn" svgIcon="close" *ngSwitchCase="false"></mat-icon>
                    Audience
                </h3>
            </div>

            <div [ngSwitch]="checkSendForm.valid">
                <h3 style="display:flex; align-items: center">
                    <mat-icon class="icon-success" svgIcon="check" *ngSwitchCase="true"></mat-icon>
                    <mat-icon class="icon-warn" svgIcon="close" *ngSwitchCase="false"></mat-icon>
                    Send
                </h3>
            </div>

        </mat-expansion-panel> -->
        <img src="../../../assets/images/preview.jpg" alt="">
        <mat-card>Coming soon.</mat-card>
    </div>
    <div class="content">
                <mat-accordion class="step-content">
                    <mat-expansion-panel hideToggle [expanded]="contentPanel">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon svgIcon="pencil-outline"></mat-icon>
                                {{'app.wizard.panel.content.expansionPanel.headline1' | translate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <form [formGroup]="contentForm">
                            <mat-form-field class="full-width">
                                <mat-label>{{'app.wizard.panel.content.titleLabel' | translate}}
                                </mat-label>
                                <input maxlength="50" matInput formControlName="title" required>
                                <span matSuffix>{{contentForm.value.title.length}}/50</span>
                            </mat-form-field>
                            <mat-form-field class="full-width message">
                                <mat-label>{{'app.wizard.panel.content.messageLabel' | translate}}
                                </mat-label>
                                <textarea [maxlength]="maxMessageLength" matInput formControlName="message"
                                    required></textarea>
                                <mat-hint *ngIf="contentForm.value.message.length > 420" align="start">
                                    {{'app.wizard.panel.content.warning' | translate}}
                                </mat-hint>
                                <mat-hint align="end">{{contentForm.value.message.length}}/{{maxMessageLengthVisable}}
                                </mat-hint>
                            </mat-form-field>

                            <div class="icon-image-option" *ngIf="features.images" >
                                <div formGroupName="imageUrl">
                                    <mat-checkbox formControlName="isSet" (change)="checkImageIcon()" color="primary">
                                        {{'app.wizard.panel.content.attachImageLabel' | translate}}
                                    </mat-checkbox>
                                    <mat-form-field class="full-width" appearance="fill"
                                    *ngIf="contentForm.value.imageUrl.isSet">
                                    <span matPrefix>https://</span>
                                    <input placeholder="www.example.com" formControlName="url" matInput type="text">
                                </mat-form-field>
                            </div>
                            <div formGroupName="iconUrl">
                                <mat-checkbox formControlName="isSet" (change)="checkImageIcon()" color="primary">
                                    {{'app.wizard.panel.content.attachIconLabel' | translate}}
                                </mat-checkbox>
                                    <mat-form-field class="full-width" appearance="fill"
                                        *ngIf="contentForm.value.iconUrl.isSet">
                                        <span matPrefix>https://</span>
                                        <input placeholder="www.example.com" formControlName="url" matInput type="text">
                                    </mat-form-field>
                                </div>
                            </div>

                            <p>{{'app.wizard.panel.content.hint' | translate}}</p>
                            <div formArrayName="interaction">
                                <div *ngFor="let action of actionForms.controls; let i = index; let first = first; let last = last">
                                    <mat-hint class="action-hint" *ngIf="i === 1" >
                                        {{'app.wizard.panel.content.actionHint' | translate }}
                                    </mat-hint>
                                    <div class="action-wrapper full-width">
                                    <button class="remove-action" *ngIf="!first" (click)="deleteAction(i)"
                                        mat-flat-button mat-icon-button>
                                        <mat-icon svgIcon="trash-can-outline"></mat-icon>
                                    </button>
                                    <mat-form-field appearance="fill" [formGroupName]="i">
                                        <mat-label>{{'app.wizard.panel.content.actionLabel' | translate}}</mat-label>

                                        <mat-select (selectionChange)="changeAction(i)"
                                            formControlName="action">
                                            <mat-option *ngFor="let item of actions" [value]="item.name"
                                                [attr.field]="item.name">
                                                {{item.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <div *ngIf="!first" >
                                        <mat-form-field appearance="fill" [formArrayName]="i">
                                            <mat-label>Name</mat-label>
                                            <input formControlName="name" matInput type="text">
                                        </mat-form-field>
                                    </div>

                                    <div [formArrayName]="i">
                                        <div *ngFor="let field of actions">
                                            <div
                                                *ngIf="field.name === actionForms.value[i].action &&
                                                (field.custom_fields | json) != '{}'">
                                                <mat-form-field formGroupName="custom" appearance="fill">
                                                    <mat-label class="action-label">{{field.key}}</mat-label>
                                                    <input formControlName="id" matInput
                                                        type="text">
                                                </mat-form-field>
                                            </div>
                                            <!-- <div
                                                *ngIf="field.name === actionForms.value[i].action && field.custom_fields.hasOwnProperty('link')">
                                                <mat-form-field class="link" formGroupName="custom" appearance="fill">
                                                    <mat-label>Link</mat-label>
                                                    <input formControlName="id" matInput
                                                        type="text">
                                                </mat-form-field>
                                            </div> -->
                                        </div>
                                        </div>
                                    </div>

                                    <!-- <div class="link-input" *ngIf="action.value.action === 'openNews'; else notWeb">
                                        <div [formGroupName]="i">

                                            <mat-form-field formGroupName="custom" appearance="fill">

                                                <span matPrefix>https://</span>
                                                <input placeholder="www.example.com" type="text" matInput
                                                    formControlName="actioncustom1">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <ng-template #notWeb>
                                        <div class="link-input">
                                            <mat-form-field [formGroupName]="i" appearance="fill">
                                                <mat-label>{{'APP__type' | translate}}</mat-label>
                                                <input type="text" matInput formControlName="type">
                                            </mat-form-field>
                                        </div>
                                        <div class="link-input">
                                            <mat-form-field [formGroupName]="i" appearance="fill">
                                                <mat-label>{{'APP__itemId' | translate}}</mat-label>
                                                <input type="text" matInput formControlName="itemid">
                                            </mat-form-field>
                                        </div>
                                    </ng-template> -->
                                </div>

                                <mat-divider></mat-divider>
                                <br>

                                <button (click)="addActions()" [disabled]="actionForms.length === 3" mat-flat-button
                                    color="primary" class="add-button"
                                    [matTooltip]="'app.wizard.panel.content.actionHint' | translate"
                                    [matTooltipPosition]="'right'"
                                    >
                                    <mat-icon svgIcon="plus-circle-outline"></mat-icon>
                                    {{'app.wizard.panel.content.addAction' | translate}}
                                </button>
                                <mat-hint>{{actionForms.length}} {{'app.wizard.panel.content.numberOfActions' | translate}}</mat-hint>
                            </div>

                        </form>
                    </mat-expansion-panel>
                </mat-accordion>
    </div>
    </div>

    <mat-dialog-actions>
        <div class="button-group">
            <button class="hint-button" mat-dialog-close mat-flat-button>
                <mat-icon svgIcon="close"></mat-icon>
                {{'app.button.close' | translate}}
            </button>
            <button [disabled]="checkContentForm.invalid" mat-flat-button (click)="saveTemplate()"
                    color="primary">{{'app.button.saveTemplate' | translate}}</button>
        </div>
    </mat-dialog-actions>