<div [ngSwitch]="data.type">
    <div *ngSwitchCase="0" class="toast-content">
        <mat-icon svgIcon="check"></mat-icon>
        {{'app.toast.successPush' | translate}}
    </div>
    <div *ngSwitchCase="1" class="toast-content">
        <mat-icon svgIcon="clock-outline"></mat-icon>
        {{'app.toast.successSchedule' | translate}} {{data.schedule.schedule.date | localDate:'dd.MM.yyyy'}} - {{data.schedule.schedule.time}}
    </div>
    <div *ngSwitchCase="2" class="toast-content">
        <mat-icon svgIcon="cog-outline"></mat-icon>
        {{'app.toast.successAutomated' | translate}} {{data.schedule.trigger}}
    </div>
    <div *ngSwitchCase="3" class="toast-content">
        <mat-icon svgIcon="alert-circle-outline"></mat-icon>
        {{'app.toast.couldntSave' | translate}}
    </div>
    <div *ngSwitchCase="4" class="toast-content">
        <mat-icon svgIcon="check"></mat-icon>
        {{'app.toast.successTemplate' | translate}}
    </div>
    <div *ngSwitchCase="5" class="toast-content">
        <mat-icon svgIcon="alert-circle-outline"></mat-icon>
        {{'app.toast.errorTemplate' | translate}}
    </div>
</div>