import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {CrudService} from '../generic/crud/crud.service';

@Injectable({
    providedIn: 'root'
})
export class ContentService extends CrudService {

    // get api url from environment file (The field is extension of the super class field)
    protected url = environment.api + '/content';

    constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

}
