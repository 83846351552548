import { debounceTime } from 'rxjs/operators';
import { ListResponse } from './../../interfaces/base/response';
import { CampaignService } from './../../services/campaign/campaign.service';
import { SearchParam } from './../../interfaces/base/search-param';
import { Campaign } from './../../interfaces/campaign';
import { Subscription, Subject } from 'rxjs';
import { WizardComponent } from './../../components/wizard/wizard.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-automate',
  templateUrl: './automate.component.html',
  styleUrls: ['./automate.component.scss'],
})
export class AutomateComponent implements OnInit, AfterViewInit, OnDestroy {
  searchTerm = '';

  // displayedColumns = ["title", "trigger", "sent", "audience", "status"];
  // dataSource: MatTableDataSource<UserData>;

  update = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public readonly defaultFilterStatus = 'publish';

  public readonly defaultLimit = 10;

  public readonly defaultOffset = 0;

  public readonly defaultMinPage = 0;

  searchParam: SearchParam = {
    filter_status: this.defaultFilterStatus,
    limit: this.defaultLimit,
    offset: this.defaultOffset,
    search: '',
  };

  isLoading = true;
  tableIsLoading = false;

  overallSize = 0;
  hasPrevPage = false;
  hasNextPage = false;
  isLoadingFailed = false;
  currentPage = this.defaultMinPage;
  displayedColumns = ['title', 'status', 'trigger'];
  dataSource: MatTableDataSource<Campaign> = new MatTableDataSource<Campaign>();

  private subscribedSubscriptions: Subscription = new Subscription();
  private searchInput$: Subject<string> = new Subject<string>();
  private searchSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private campaignService: CampaignService
  ) {}

  ngOnInit(): void {
    this.initSearchSubscription();
    this.findAllCampaigns(this.searchParam);
  }

  ngOnDestroy(): void {
    // unsubscribe all subscriptions
    this.subscribedSubscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  initSearchSubscription() {
    this.subscribedSubscriptions.add(
      (this.searchSubscription = this.searchInput$
        .pipe(debounceTime(1000))
        .subscribe(() => {
          this.findAllCampaigns(this.searchParam);
        }))
    );
    this.subscribedSubscriptions.add(this.searchInput$);
  }

  findAllCampaigns(searchParam?: SearchParam) {
    this.subscribedSubscriptions.add(
      this.campaignService.findAll(searchParam).subscribe(
        (response: ListResponse<Campaign>) => {
          this.isLoading = false;
          this.isLoadingFailed = false;
          this.overallSize = response.overallsize;
          // overall size is counting from 1 but offset is counting from 0.
          // if overall is bigger then offset and returned list is full - means that there are more records.
          this.hasNextPage =
            response.overallsize - 1 - this.searchParam.offset > 0 &&
            response.list.length >= this.defaultLimit;
          this.hasPrevPage = this.currentPage > this.defaultMinPage;
          this.dataSource.data = response.list;
        },
        () => {
          this.isLoading = false;
          this.isLoadingFailed = true;
        }
      )
    );
  }

  runSearch() {
    this.searchInput$.next();
  }

  clearSearch() {
    this.searchParam.search = '';
    this.searchInput$.next();
  }

  reloadPage(emitEvent) {
    if (emitEvent) {
      setTimeout(() => {
      this.findAllCampaigns(this.searchParam);
      }, 500);
    }
  }

  prevPage() {
    if (this.currentPage > this.defaultMinPage) {
      this.currentPage--;
      this.searchParam.offset -= this.defaultLimit;
      this.findAllCampaigns(this.searchParam);
    }
  }

  nextPage() {
    this.currentPage++;
    this.searchParam.offset += this.defaultLimit;
    this.findAllCampaigns(this.searchParam);
  }

  openWizard(first: number, second: number, third: number) {
    this.dialog
      .open(WizardComponent, {
        width: '90%',
        height: '75%',
        maxWidth: '1100px',
        maxHeight: '600px',
        panelClass: 'wizard-panel',
        data: {
          first,
          second,
          third,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res === true) {
          this.update = true;
          this.reloadPage(true);
        }
      });
  }
}
